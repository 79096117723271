<template lang="pug">
.dev
  ul
    li {{ userFbId }}
    li {{ userName }}
    li {{ userPic }}
    li
      img(:src="userPic")
    li
      img(:src="userPicBase64")
    li {{ isUserLogin }}
  ul
    li(@click="loginFb") login to FB
    li(@click="createOrGetUser") create or get user
    li(@click="checkFbStatus") checkFbStatus
    li(@click="getUserFbData") getUserFbData
    li(@click="logoutFB") logout FB
    li(@click="getImg") getimg
    li(@click="vote(1)") vote
    li(@click="getVideos") get videos
</template>

<script>
import { createOrGetUser, vote, getVideos } from "@/api";
import { initFacebookSDK } from "@/utils/init-facebook-sdk";
import request from "@/utils/request";

export default {
  name: "Dev",
  components: {},
  data: () => {
    return {
      showMask: false,
      userFbId: null,
      userName: null,
      userPic: null,
      userPicBase64: null,
      isUserVotedToday: false,
      isUserLogin: false,
    };
  },
  mounted() {
    initFacebookSDK()
      .then(() => {
        this.checkFbStatus();
      })
      .then(() => {
        if (this.isUserLogin) {
          this.getUserFbData();
        }
      });
  },
  methods: {
    getVideos(){
        let queries = {
          limit: 25
        }
        getVideos(queries).then(r=>{
            console.log(r)
        })
    },
    createOrGetUser() {
      let userData = {
        fbuid: this.userFbId,
        username: this.userName,
        userPic: this.userPicBase64,
      };
      createOrGetUser(userData).then((response) => {
        console.log(response);
      });
    },
    vote(videoId){
        vote(this.userFbId, videoId).then(response=>{
            console.log(response)
        })
    },
    loginFb() {
      FB.login((response) => {
          console.log('login')
          console.log(response)
          if(response.status === "connected"){
              this.isUserLogin = true;
              this.userFbId = response.authResponse.userID;
              this.getUserFbData();
          }
        // this.checkFbStatus().then(() => {
        //   if (this.isUserLogin) {
        //     this.getUserFbData();
        //   }
        // });
      });
    },
    checkFbStatus() {
      return new Promise((resolve) => {
        FB.getLoginStatus((response) => {
            // console.log(response)
          if (response.status === "connected") {
            this.isUserLogin = true;
            this.userFbId = response.authResponse.userID;
          }else{
              console.log('not log in')
          }
          resolve();
        });
      });
    },
    getUserFbData() {
      FB.api("/me", { fields: "id,name,picture" }, (response) => {
        console.log(response);
        this.userName = response.name;
        this.userPic = response.picture.data.url;
        this.getImg()
      });
    },
    logoutFB() {
      FB.logout((response) => {
        this.isUserLogin = false;
        this.userName = null;
        this.userFbId = null;
      });
    },
    getImg() {
      const reader = new FileReader();
      request({
        responseType: "blob",
        url: this.userPic,
        method: "get",
      }).then((res) => {
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(res);
        }).then(res => {
          this.userPicBase64 = res
        });
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.dev
    li
        cursor: pointer
</style>
